import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import "../scss/index.scss";

import {LayoutPages, Seo} from "../components";
import Services from '../modules/Services/containers/ServicesContainer';


const ServicesPage = ({languageSelect}) => {
    return (
        <StaticQuery
            query={graphql`
              query  {
                contentfulServices {
                    subtitle_ru
                    subtitle_en
                    subtitle_cn
                    desc_ru
                    desc_en
                    desc_cn
                    seo {
                      title_ru
                      title_en
                      title_cn
                      description_ru
                      description_en
                      description_cn
                      keywords_ru
                      keywords_en
                      keywords_cn
                    }
                }
              }
            `}
            render={data => {
                const breadcrumbs = [
                    {
                        pathname: "/", crumbLabel: `${languageSelect === 'ru' ? 'Главная' : languageSelect === 'en' ? 'Home' : '首頁'}`, active: false
                    },
                    {
                        pathname: "", crumbLabel: `${data.contentfulServices["subtitle_" + languageSelect]}`, active: true
                    }
                ];
                return (
                    <LayoutPages titlePage={data.contentfulServices["subtitle_" + languageSelect]}
                                 titleDesc={data.contentfulServices["desc_" + languageSelect]}
                                 breadcrumbs={breadcrumbs}
                    >
                        <Seo title={`${data.contentfulServices.seo["title_" + languageSelect]}`}
                             description={`${data.contentfulServices.seo["description_" + languageSelect]}`}
                             keywords={`${data.contentfulServices.seo["keywords_" + languageSelect]}`}
                        />
                        <Services/>
                    </LayoutPages>
                );
            }}
        />

    );
};

let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(ServicesPage);
