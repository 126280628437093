import React from 'react';
import {Link} from "gatsby";

const ServicesItem = ({subtitle, descShort, img, imgAlt, buttonDetailName, detailLink}) => {
    return (
        <div className="services-item">
            <div className="services-item-wrapper">
                <Link to={detailLink} className="subtitle-link subtitle-link_color_white">
                    <h3 className="subtitle subtitle_lvl_three services-item__name">
                        {subtitle}
                    </h3>
                </Link>
                <div className="services-item__text">
                    {descShort}
                </div>
            </div>
            <img src={img} alt={imgAlt} className="services-item__img"/>
            <Link to={detailLink} className="services-item__link">{buttonDetailName}</Link>
        </div>
    );
};


export default ServicesItem;