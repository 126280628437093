import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";

import ServicesItem from '../components/ServicesItem';


const ServicesContainer = ({languageSelect}) => {

    return (
        <StaticQuery
            query={graphql`
              query  {
                contentfulServices {
                  button_ru
                  button_en
                  button_cn
                  servicesItems{
                      id
                        subtitle_ru
                        subtitle_en
                        subtitle_cn
                        desc_ru{
                            desc_ru
                        }
                        desc_en{
                            desc_en
                        }
                        desc_cn{
                            desc_cn
                        }
                        img{
                            title
                            file{
                                url
                            }
                        }
                      
                  }
                }
              }
            `}
            render={data => {

                const ServiceItems = data.contentfulServices.servicesItems.map(item => {
                    return (
                        <ServicesItem key={item.id}
                                      subtitle={item['subtitle_' + languageSelect]}
                                      descShort={item["desc_" + languageSelect]["desc_" + languageSelect]}
                                      detailLink={'/services/' + item.subtitle_en.toLocaleLowerCase().replace(/ /g, '-')}
                                      img={item.img.file.url}
                                      imgAlt={item.img.title}
                                      buttonDetailName={data.contentfulServices["button_" + languageSelect]}
                        />
                    );
                });
                return (
                    <section className="section">
                        <div className="container">
                            <div className="services">
                                {ServiceItems}
                            </div>
                        </div>
                    </section>
                );
            }}
        />
    );
};



let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(ServicesContainer);